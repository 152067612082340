<template>
  <div style="width: 1200px;" class="mt-3">
    <div class="form-section" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses">
      <h4>Select UI Color Schema</h4>
    </div>


    <div class="form-section mb-2">
      <schema v-for="(schema, key) in schemas" :key="key" :schema="schema"></schema>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      schemas: $services['MODULE-App-UiColorSchema'].schemas
    }
  },
  components: {
    'schema': () => import('./Schema')
  }
}
</script>
